import React, { useEffect, useState } from 'react'
import { BASE } from './../../basedata/constants'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'

interface Props {
  onResponse: (response: any) => void
}

const DocumentTypeErrorHandler = ({ onResponse }: Props) => {

  const resetProccess = async () => {

   const currentToken:any = window.sessionStorage.getItem('token')

    return fetch(`${BASE.api.base_url}${BASE.api.invalidate_document}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        token: currentToken,
      })
    })
      .then((resg) => resg.json())
      .then((responseSave) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('doc', 'false');
        window.location.search = searchParams.toString();
        onResponse({ step: 'faceproceed' })
      })
      .catch((error) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('doc', 'false');
        window.location.search = searchParams.toString();
        onResponse({ step: 'faceproceed' })
      })
  }

  return (
    <>
      <h1>
        <span>Ooops!</span> <br/>
        Documento inválido
      </h1>
      <hr />
      <p>
      Infelizmente, não foi possível validar o documento fornecido. <br/><br/>
      Por favor, tente novamente com um novo documento válido, atualizado e em boas condições de legibilidade e conservação.
      </p>

      <div className="form-group form-submit">
        <a
          className="btn btn-primary form-control form-control-lg"
          onClick={() => {resetProccess()}}
        >
          Refazer
        </a>
      </div>
    </>
  )
}

export default DocumentTypeErrorHandler
